import React from "react"
import Img from "gatsby-image"

import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"

const VereinPage = () => {
  const data = useStaticQuery(graphql`
    query {
      vorstand: file(relativePath: { eq: "vorstand.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      schuetzenhaus: file(relativePath: { eq: "schützenhaus.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Verein" lang="de" />
      <Container>
        <h1 className="h3">Verein</h1>
        <hr className="featurette-divider" />
        <h6>Kurze Historie</h6>
        <p>
          Der Schützenverein 1961 Gambach e.V. wurde am 15. Oktober 1961 von 15
          schießsportbegeisterten Männern im Gambacher Gasthaus „Zum
          Rosengarten“ gegründet.
        </p>
        <p>
          Die Gründungsmitglieder (in alphabetischer Reihenfolge): Eugen
          Fenchel, Heinz Fenchel (†), Ewald Hölzemann, Kurt Mayer (†), Volker
          Mohr, Dieter Näck, Helmut Pflimpfl (†), Heinz Puschner (†), Gerhard
          Rieber, Fritz-Karl Schäfer, Heinz Schäfer (†), Erwin Schwenz (†),
          Gerhard Stork, Gerhard Vogt, Dieter Zeiß (†).
        </p>
        <p>
          Der Vorstand im Gründungsjahr bestand aus dem 1. Vorsitzenden Erwin
          Schwenz, dem 2. Vorsitzenden Heinz Schäfer, dem Schriftführer Kurt
          Mayer, dem Kassenwart Eugen Fenchel, dem 1. Schießwart Gerhard Rieber,
          dem 2. Schießwart Heinz Fenchel, dem 3. Schießwart Volker Mohr und dem
          Gerätewart Dieter Zeiß.
        </p>
        <p>
          Nach provisorischen Anfängen im Gasthaus „Zum Rosengarten“, wo die
          Schießstände immer wieder auf- und abgebaut werden mussten, zog der
          Verein 1964 auf die ehemalige Kegelbahn des Gasthauses „Zur schönen
          Aussicht“. Hier wurden in Eigenleistung vier Luftgewehr-Schießstände
          fest installiert (davon 1 Wechselstand für Zimmerstutzen). Im gleichen
          Jahr erfolgte die Aufnahme des Schießens mit dem Kleinkaliber auf dem
          Schießstand des Schützenverein 06 Friedberg, mit der unvorhergesehenen
          Erringung des Kreismeistertitels.
        </p>
        <p>
          Im folgenden Jahrzehnt 1966–1976 erzielte der Verein trotz
          beschränkter Standkapazität hervorragende Ergebnisse bei den Kreis-
          und Gaumeisterschaften. Zum Teil qualifizierten sich Schützen für die
          Hessischen Landesmeisterschaften.
        </p>
        <p>
          Infolge der Schließung des Gasthauses „Zur Schönen Aussicht“ erfolgte
          im Herbst 1976 der Umzug in den „Gasthof Ruppel“, wo drei der
          vorhandenen Schießstände in der ehemaligen Kegelbahn aufgebaut werden
          konnten. Dies war jedoch lediglich ein Interims-Domizil, da bereits in
          diesem Jahr mit dem Neubau des Bürgerhauses Gambach begonnen wurde, wo
          im Untergeschoß ein Luftgewehr-Schießstand vorgesehen war. Anfang 1977
          wurden dann im Bürgerhaus sechs automatische Luftgewehr-Schießstände
          in Eigenleistung eingebaut und im März 1977 der Schießbetrieb im
          Bürgerhaus aufgenommen.
        </p>
        <p>
          Hier wurden dann die nächsten 32 Jahre bis August 2009 mit vielen
          gesellschaftlichen und sportlichen Höhepunkten verbracht, die einzelne
          Schützen bis zur Deutschen Meisterschaft führten. Im Jahre 2004 wurde
          dann der Entschluss zu einem Neubau gefasst und im Jahre 2008 mit den
          Bauarbeiten begonnen.
        </p>
        <h6 className="mt-4">Heute</h6>
        <p>
          Der Schützenverein ist Mitglied des Hessischen Schützenverbandes im
          Deutschen Schützenbund. Der Schützenverein bezog am 22. August 2009
          das neue großzügige Schützenhaus in der Bahnhofstraße 88 in dem der
          Schießstand für die Luftdruckwaffen sowie der Aufenthaltsraum und die
          Sozialräume untergebracht sind. Die Kleinkaliber-Schützen tragen ihre
          Wettkämpfe auf dem Schießstand des Schützenvereins Hüttenberg aus.
        </p>
        <Row className="justify-content-center">
          <Col xs={8}>
            <Img fluid={data.vorstand.childImageSharp.fluid} />
            <small className="d-block text-center">
              Der Vorstand des Schützenverein 1961 Gambach e.V. (von links nach
              rechts): Heiko Lippert (Stand- und Gerätewart) Rigo Schubach
              (Beisitzer) Fabienne Hofmann (Vergnügungsausschuss) Hendrik Ruf
              (Beisitzer) Harald Fink (Schießwart) Michael Rheinfrank
              (Jugendwart) Lothar Döring (Kassenwart) Friedhelm Schwenz
              (Vorsitzender) Lars Wächter (Beisitzer) Rudolf Haas (stv.
              Vorsitzender) Katharina Beppler-Alt (Vergnügungsausschuss) Willi
              Beppler-Alt (Schriftführer)
            </small>
            <p></p>
          </Col>
          <Col xs={8}>
            <Img fluid={data.schuetzenhaus.childImageSharp.fluid} />
            <small className="d-block text-center">
              Das Schützenhaus des Schützenverein 1961 Gambach e.V.
            </small>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default VereinPage
